<script>
export default {
	mounted() {
		let iframe = window.document.createElement('iframe')

		this.$nextTick(() => {
			this.$refs.pdf.appendChild(iframe)
		})

		iframe.src = 'about:blank'
		iframe.style.width = '100%'
		iframe.style.height = '80vh'
		iframe.sandbox =
			'allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-top-navigation-by-user-activation allow-downloads'

		iframe.addEventListener('load', async () => {
			let doc = iframe.contentDocument || iframe.contentWindow.document
			doc.body.innerHTML = `<div class="flipbook-container"></div>`
			let flipBookConfig = {
				pdf: this.$route.query.pdf,
				template: { sounds: { startFlip: '/sounds/start-flip.mp3', endFlip: '/sounds/end-flip.mp3' } },
			}
			let srcs = [
				'/js/jquery.min.js',
				'/js/html2canvas.min.js',
				'/js/three.min.js',
				'/js/pdf.min.js',
				'/js/3dflipbook.min.js',
				`$('.flipbook-container').FlipBook(${JSON.stringify(flipBookConfig)})`,
			]

			for (let src of srcs) {
				let script = doc.createElement('script')
				if (src.startsWith('/')) script.src = src
				else script.textContent = src
				doc.body.appendChild(script)
				await new Promise((resolve) => script.addEventListener('load', resolve))
			}
		})
	},
}
</script>

<template>
	<div>
		<div ref="pdf" style="width: 100%; height: 100%"></div>
	</div>
</template>
